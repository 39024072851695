<mat-drawer-container autosize>

  <mat-drawer-content style="width:100%;">

    <ng-template appTopbarActions>
      <button mat-mini-fab color="accent" (click)="toggleInscriptionsOptions()" matTooltip="Options d'affichage">
        <mat-icon>settings</mat-icon>
      </button>
    </ng-template>

    <div id="inscription-conteneur" class="thin-scrollbar" *ngIf="inscriptionsFiltered && config; else loader">

      <mat-card *ngIf="config.infoMessageList" style="margin: 5px;" [portailCustomStyle]="config.styleCardInfoList">
        <div [innerHtml]="config.infoMessageList | safe"></div>
      </mat-card>

      <div fxLayout="column">
        <ng-container *ngFor="let inscription of inscriptionsFiltered">
          <mat-card class="card" *ngIf="(!renouvellementInscriptionsEnabled &&
        !inscription.isCompleted) || renouvellementInscriptionsEnabled">
            <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between start">
              <mat-card-title fxLayout="row" fxLayoutAlign="start center">
                <mat-icon *ngIf="inscription.idAdulte" style="margin-right: 5px;">person</mat-icon>
                <app-icon *ngIf="inscription.idEnfant" style="margin-right: 5px;" type="do" icon="enfant"></app-icon>
                <span>{{ inscription.nomReferent }}</span>
              </mat-card-title>
              <div fxLayout="row" fxLayoutAlign="start center">
                <app-icon style="margin-right: 5px;" type="do" icon="annee-scolaire"></app-icon>
                <span class="label-anneeScolaire" matTooltip="Année scolaire">{{ inscription.anneeScolaireName }}</span>
              </div>
            </mat-card-header>

            <mat-card-content style="margin-left: 20px;">
              <div *ngIf="inscription.depDiscNivGroup.length &&
              config.anneeScolaire === inscription.anneeScolaire &&
              renouvellementInscriptionsEnabled &&
              inscription.etatRenouvellement === 'A traiter'" fxLayout="row" fxLayoutAlign="start center"
                class="info-renouvellement">
                <mat-icon color="warn" style="margin-right: 5px; overflow: visible;">warning</mat-icon>
                <span *ngIf="inscription.isCompleted">{{'lasido.info-text-renouvellement-non-complete' | trans}}</span>
                <span *ngIf="!inscription.isCompleted">{{'lasido.info-text-renouvellement-complete' | trans}}</span>
              </div>

              <div *ngFor="let depDiscNiv of inscription.depDiscNivGroup" class="list-discipline">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <app-icon style="margin-right: 5px;" type="do" icon="departement"></app-icon>
                    <div style="font-weight: 500;">
                      <span *ngIf="depDiscNiv.departementName">
                        {{ depDiscNiv.departementName }}<span class="separator">-</span>
                      </span>
                      <span>{{ depDiscNiv.disciplineName }}<span class="separator">-</span>
                      </span>
                      <span>{{ depDiscNiv.niveauName }}</span>
                    </div>
                  </div>
                  <mat-icon *ngIf="config.anneeScolaire === inscription.anneeScolaire && depDiscNiv.renewal !== ''
                  && inscription.etatRenouvellement === 'A traiter'
                  && depDiscNiv.etatRenouvellement === 'A traiter'"
                    [color]="depDiscNiv.renewal === 'oui' ? 'primary' : 'warn'"
                    [matTooltip]="depDiscNiv.renewal === 'oui' ? 'Renouvelé' : 'Non renouvelé'">
                    {{depDiscNiv.renewal === 'oui' ? 'check_circle' : 'cancel'}}</mat-icon>
                </div>
                <div *ngFor="let cours of depDiscNiv.cours" style="margin-left: 35px;">
                  <div style="margin-top: 10px;">
                    <label>{{cours.choice ? cours.ordre + ' -' : '-'}} {{cours.libelle}}</label>
                  </div>
                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Horaire : </label>
                    <span *ngIf="!cours.provisoire; else coursProvisoire">Le {{cours.jour}}
                      de
                      {{cours.heureDebut}} à
                      {{cours.heureFin}}</span>
                  </div>
                  <ng-template #coursProvisoire>
                    <span>{{'lasido.text-cours-provisoire' | trans}}</span>
                  </ng-template>

                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Enseignant(s) : </label>
                    <span *ngFor="let enseignant of cours.enseignants; let last = last" style="margin-right: 5px;">
                      {{enseignant.nomPrenom}}{{!last ? ' - ' : ''}}
                    </span>
                  </div>
                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Type : </label>
                    <span>{{cours.individuel ? 'Individuel' : 'Collectif'}}</span>
                  </div>
                </div>
                <div
                  *ngIf="renouvellementInscriptionsEnabled && config.anneeScolaire === inscription.anneeScolaire && !depDiscNiv.disciplineReservable"
                  fxLayout="row" fxLayoutAlign="end center" style="margin-top: 15px;">
                  <em>{{'lasido.text-inscription-non-reservable' | trans}}</em>
                </div>
                <div
                  *ngIf="depDiscNiv.etatRenouvellement === 'A traiter' && config.anneeScolaire === inscription.anneeScolaire && depDiscNiv.renewal !== ''"
                  fxLayout="row" fxLayoutAlign="end center" style="margin-top: 15px;">
                  <em>{{'lasido.text-renouvellement-en-attente' | trans}}</em>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions *ngIf="inscription.depDiscNivGroup.length &&
            config.anneeScolaire === inscription.anneeScolaire &&
            renouvellementInscriptionsEnabled &&
            inscription.etatRenouvellement === 'A traiter'" align="center">
              <button mat-raised-button color="primary" (click)="onClickRenouvellement(inscription)"
                [disabled]="inscription.allNonReservable">
                {{'lasido.renouveler' | trans }}
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-container>



        <div *ngIf="!inscriptions.length">
          <h2 style="text-align: center;">Vous n'avez pas d'inscription(s).</h2>
        </div>
      </div>

      <mat-card *ngIf="permToCreatePreInscription" class="card card-add">
        <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">
          <mat-card-title fxLayout="row" fxLayoutAlign="center center">
            Pré-inscription à une nouvelle discipline
          </mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="row" fxLayoutAlign="center center">
          <button mat-fab color="primary" routerLink="/account/lasido-preinscriptions/new">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

    </div>

  </mat-drawer-content>

  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="preview-header" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <mat-form-field class="no-hint" style="width: 100%;">
      <mat-label>Filtrer par Année scolaire</mat-label>
      <mat-select [(ngModel)]="filter.anneesScolaires" (ngModelChange)="changeSelectAnneeScolaire($event)" multiple>
        <mat-option *ngFor="let anneeScolaire of anneesScolaires" [value]="anneeScolaire.idAnneeScolaire">
          {{anneeScolaire.designation}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="no-hint" style="width: 100%;">
      <mat-label>Filtrer par usager</mat-label>
      <mat-select [(ngModel)]="filterUsagers" (ngModelChange)="changeSelectUsager($event)" multiple>
        <mat-option *ngFor="let usager of listUsagers" [value]="usager">
          {{usager.nomReferent}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
