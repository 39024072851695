import { Component, forwardRef, OnInit, NgZone, Input, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DocumentPickerComponent } from '@app/components/_admin/file/document-picker/document-picker.component';
import { PlatformService, UploadedFile } from '@app/services';
import { environment } from '@env/environment';
import { EditorOptions, Editor as TinyEditor } from 'tinymce';
import { CodeEditorComponent } from '../code-editor/code-editor.component';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';


const EDITOR_COMPONENT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => TextEditorComponent),
  multi: true
};

const defaultToolbar = `
    undo redo | cut copy paste pastetext | bold italic underline strikethrough | fontfamily
   | fontsize | forecolor backcolor | removeformat | alignleft aligncenter alignright alignjustify 
   | image imagepicker | documentlink link unlink | bullist numlist | outdent indent | hr | table
   | charmap emoticons | visualblocks monaco code custompreview`;

const defaultPlugins = [
  'lists', 'advlist', 'table', 'image', 'editimage',
  'link', 'autolink', 'charmap', 'emoticons', 'quickbars',
  'visualblocks', 'advcode', 'autoresize'
];

type EditorType = 'TinyMCE' | 'Monaco';
const commentForMonaco = '<!-- editorType:Monaco -->';
const commentForTinyMCE = '<!-- editorType:TinyMCE -->';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [EDITOR_COMPONENT_VALUE_ACCESSOR]
})
export class TextEditorComponent implements OnInit, ControlValueAccessor {

  @Input() init: EditorOptions;
  @Input() editorType: EditorType = "TinyMCE"

  @Input() codeEditorOptions = {
    theme: 'vs-dark', language: 'html',
    automaticLayout: true
  };

  @Input() allowMonacoSwitch = false;
  @Input() allowImages: boolean = true;

  minHeight: number;

  @ViewChild(EditorComponent) mceEditor: EditorComponent;
  @ViewChild(CodeEditorComponent) monacoEditor: CodeEditorComponent;
  @ViewChild('previewTemplate') previewTemplate: TemplateRef<any>
  @ViewChild("monacoBox") public monacoBox: ElementRef<any>;


  monacoEditorActive: boolean;

  defaultSettings: (EditorOptions | any) = {
    plugins: defaultPlugins,
    external_plugins: {
      advcode: '/tinymce/custom-plugins/custom-code/plugin.min.js',
      editimage: '/tinymce/custom-plugins/custom-edit-image/plugin.min.js',
      powerpaste: '/tinymce/custom-plugins/custom-powerpaste/plugin.min.js'
    },
    toolbar: defaultToolbar,
    toolbar_mode: 'sliding',
    image_class_list: [
      { title: 'Responsive', value: 'img-responsive' }
    ],
    menubar: false,
    contextmenu: ['link image imagetools table'],
    quickbars_selection_toolbar: 'bold italic underline | fontsizeselect | forecolor | quicklink | removeformat',
    quickbars_insert_toolbar: false, // 'quickimage quicktable',
    convert_urls: false,

    powerpaste_word_import: 'prompt',
    powerpaste_googledocs_import: 'prompt',
    powerpaste_html_import: 'prompt',

    // forced_root_block: '', // Uncomment this line causes an error on every "backspace" hit
    newline_behavior: 'linebreak', // replace the behavior of force_root_block:'' which is no longer supported

    language: 'fr_FR',
    language_url: '/assets/i18n/tinymce/fr_FR.js',
    branding: false,
    height: 300,
    min_height: 200,
    max_height: 700,
    resize: true,

    setup: (editor: TinyEditor) => { this.setupTinyEditor(editor) }
  };

  // Déclarations for ControlValueAccessor features
  onChange: (_: any) => void;
  private onTouch: () => void;
  internalValue: any;
  externalValue: any;


  constructor(
    private ngZone: NgZone,
    private dialog: MatDialog,
    private platformService: PlatformService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {

    this.setMinHeight()

    if (!this.allowImages) this.disableImagesFeatures()
    if (this.allowMonacoSwitch) this.disableNativeCodeFeature()

    this.init = { ...this.defaultSettings, ...this.init };
    this.setUntouchablesOptions()
  }

  setMinHeight() {
    this.minHeight = this.init?.min_height || 200
    this.elementRef.nativeElement.style.setProperty('--text-editor-min-height', this.minHeight.toString() + 'px')
  }

  setUntouchablesOptions() {
    this.init.base_url = '/tinymce'
    this.init.suffix = '.min'
  }

  setupTinyEditor(editor: TinyEditor) {
    if (this.allowImages) this.addImagePicker(editor)
    if (this.allowMonacoSwitch) this.addMonacoSwitch(editor)

    this.addDocumentLinkPicker(editor)
    this.addCustomPreview(editor)

  }

  disableImagesFeatures() { // remove some options from 'defaultSettings', so this must be called before we merge 'init' & 'defaultSettings' 
    this.defaultSettings.toolbar = this.defaultSettings.toolbar.replace('image imagepicker |', '')
    this.defaultSettings.plugins = this.defaultSettings.plugins.filter(plugin => plugin.indexOf('image') < 0)
    delete this.defaultSettings.external_plugins.editimage
    this.defaultSettings.images_file_types = '',
      this.defaultSettings.smart_paste = false,
      this.defaultSettings.paste_preprocess = (pluginApi, data) => {
        // Pour interdire l'ajout d'image par copier / coller 
        const content = data.content;
        if (content.toLowerCase().indexOf('<img') >= 0) { data.content = ''; }
      }
  }

  disableNativeCodeFeature() { // remove some options from 'defaultSettings', so this must be called before we merge 'init' & 'defaultSettings' 
    this.defaultSettings.toolbar = this.defaultSettings.toolbar.replace('code ', '')
    this.defaultSettings.plugins = this.defaultSettings.plugins.filter(plugin => plugin.indexOf('advcode') < 0)
    delete this.defaultSettings.external_plugins.advcode
  }

  addImagePicker(editor: TinyEditor) {
    editor.ui.registry.addButton('imagepicker', {
      icon: 'gallery',
      tooltip: 'Ajouter une image de la bibliothèque',
      onAction: () => this.openDocumentPicker('image', (result) => {
        if (result && result.length) {
          const doc = result[0];
          editor.insertContent('<img src="' + this.buildFileUrl(doc.id) + '" title="' + doc.name + '">');
        }
      })
      // onSetup() + editor.on('NodeChange') (like seen in doc https://www.tiny.cloud/docs/ui-components/toolbarbuttons/)
      // would allow us to set button "active" when caret passes on a picked document in editor
    });
  }

  addDocumentLinkPicker(editor: TinyEditor) {
    // tslint:disable-next-line: max-line-length
    editor.ui.registry.addIcon('document-link', `<svg height="24" width="24"><path d="M 9.2578125,2 4,7.1113281 V 19.777344 C 3.9997535,21.004601 4.6963484,21.476263 5.6305448,22 h -0.00504 C 4.9530791,20.625831 4.7652669,19.040739 5.9949506,17.889042 V 8.2307072 H 10.422113 V 3.9324506 H 18.00505 V 7.2168003 C 18.616506,7.1077984 19.45409,7.2525134 20,7.6819014 V 4.2226562 C 20.000247,2.9953992 18.977165,2.0002997 17.714844,2 Z M 20,16.396181 c -0.042,0.03954 -0.08312,0.08194 -0.125,0.121094 -1.057893,0.920545 -0.822614,0.804168 -1.86995,1.763672 V 20.06755 H 16.396263 C 15.702675,20.82396 15.324178,21.254264 14.617995,22 h 3.096849 C 18.971986,22 20,20.999566 20,19.777344 Z" /><path fill-rule="nonzero" d="m 9.081332,16.350257 c 0.7845415,-0.589235 1.775672,0.403293 1.187267,1.188941 l -1.780901,1.698488 c -1.7601467,1.037185 1.1907076,4.101644 2.289731,2.377883 l 4.07063,-4.076371 c 0.323227,-0.330223 0.323227,-0.858718 0,-1.188941 -0.916908,-0.528314 0.728967,-2.058783 1.187266,-1.104017 0.904584,0.951153 0.904584,2.445822 0,3.396975 l -4.070629,4.246219 C 8.8632825,25.562055 4.6315739,21.32438 7.300431,18.218593 l 1.696096,-1.698487 z m 9.837355,-0.509547 c -0.784541,0.58923 -1.775667,-0.403294 -1.187266,-1.188941 l 1.696096,-1.698487 c 1.906839,-0.917894 -1.149781,-4.2142806 -2.204925,-2.377883 l -4.070629,4.07637 c -0.323227,0.330223 -0.323227,0.858718 0,1.188941 0.916904,0.528316 -0.728964,2.058781 -1.187267,1.104019 -0.904585,-0.951153 -0.904585,-2.445823 0,-3.396976 l 4.070629,-4.2462142 c 3.101408,-2.6725665 7.333063,1.5650512 4.664264,4.6708352 l -1.696095,1.698488 z"/></svg>`);
    editor.ui.registry.addButton('documentlink', {
      icon: 'document-link',
      tooltip: 'Insérer un lien vers un document',
      onAction: () => this.openDocumentPicker(null, (result) => {
        if (result && result.length) {
          const doc = result[0];
          editor.insertContent('<a href="' + this.buildFileUrl(doc.id) + '">' + doc.name + '</a>');
        }
      })
    });
  }

  // Because Tiny doesn't like relative URLs and can't make a proper absolute one ...
  buildFileUrl(id) {
    return environment.apiUrl + 'files/' + id;
  }

  addCustomPreview(editor: TinyEditor) {
    editor.ui.registry.addButton('custompreview', {
      icon: 'preview',
      tooltip: 'Aperçu',
      onAction: () => {
        this.ngZone.run(() => {
          this.openPreview()

        })
      }
    });
  }

  openPreview() {
    this.dialog.open(this.previewTemplate);
  }

  toggleEditorType() {

    const message = this.editorType === 'TinyMCE' ?
    /*html*/`
        <p style="font-size:1.2em;">
          Basculer vers l\'éditeur de code ?
        </p>
      `
      :
      /*html*/`
      <div style="font-size:1.2em; ">
        <p class="warn-lighter-bg" style="padding:1em;">
          <span class="material-icons" style="float: left;font-size:48px; margin: 0.15em 0.25em  0 0;">warning </span>
          Attention, l'éditeur WYSIWIG reformattera votre contenu.
          <br>Il est possible que vous perdiez certaines de vos modifications.
        </p>
        <p>
          Voulez-vous vraiment revenir à l\'éditeur du texte <em>WYSIWYG</em> ?
        </p>
      </div>
      `
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: { message }
    });
    ref.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        if (this.editorType === 'TinyMCE') {
          this.editorType = 'Monaco';
        } else {
          this.editorType = 'TinyMCE'
        }
        this.onChange(this.internalValue)
      }
      this.monacoEditorActive = (this.editorType === 'Monaco')
    });
  }

  // Type allows to filter selectable files in DocPicker, onSelected will be called with selection result
  openDocumentPicker(type: string, onSelected: CallableFunction) {

    // After long hours of fails, found the solution there : https://github.com/angular/components/issues/7550
    // => Tiny Editor runs outside of Angular "scope" (Zone), which can be fixed with this simple line
    this.ngZone.run(() => {

      const docPicker = this.dialog.open(DocumentPickerComponent, {
        width: '90%',
        maxWidth: '1200px',
        maxHeight: '90vh',
        data: { type }
      });

      docPicker.afterClosed().subscribe((result: UploadedFile[]) => onSelected(result));
    });
  }

  addMonacoSwitch(editor: TinyEditor) {
    // tslint:disable-next-line: max-line-length
    editor.ui.registry.addIcon('customsourcecode', `<svg xmlns="http://www.w3.org/2000/svg" class="custom-source-code" viewBox="0 0 640 512"><path d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3 .8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2 .6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7 .8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"/></svg>`);
    // editor.ui.registry.addIcon('customsourcecode', `<svg class="custom-source-code" viewBox="0 0 1792 1792"  matTooltip="Info about the action" ><path d="M553 1399l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23t-10 23zm591-1067l-373 1291q-4 13-15.5 19.5t-23.5 2.5l-62-17q-13-4-19.5-15.5t-2.5-24.5l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zm657 651l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z"/></svg>`);
    editor.ui.registry.addButton('monaco', {
      icon: 'customsourcecode',
      tooltip: `Basculer vers l'éditeur de code HTML`,
      onAction: (res) => {
        this.ngZone.run(() => {
          this.toggleEditorType()
        })
      }
    })

  }

  openMonacoCommands() {
    const editor = this.monacoEditor.getEditor()
    editor.focus()
    editor.trigger(null, 'editor.action.quickCommand', null)
  }

  //  Risizing Monaco
  monacoResizing = false;

  monacoResizeStart(event: MouseEvent) {
    event.stopPropagation();
    this.monacoResizing = true
  }

  monacoResizeEnd() {
    this.monacoResizing = false
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.monacoResizing) {
      this.monacoResize(event.clientY);
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) { this.monacoResizeEnd() }

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) { this.monacoResizeEnd() }

  monacoResize(clientY) {
    const headerFooterHeigth = 60
    const monacoBoxMinHeight = 200 + headerFooterHeigth; // 200px min for editor + 60px for header/footer 

    const mouseY = clientY + window.scrollY
    const monacoWidth = this.monacoEditor.getEditor().getLayoutInfo().width // don't want to change width, but we need it to call layout() function on editor
    const monacoBoxTop = this.monacoBox.nativeElement.getBoundingClientRect().top + window.scrollY
    const monacoBoxHeight = Number(mouseY > monacoBoxTop + monacoBoxMinHeight) ? mouseY - monacoBoxTop : monacoBoxMinHeight;

    this.monacoEditor.getEditor().layout({ width: monacoWidth, height: monacoBoxHeight - headerFooterHeigth })
  }

  // For ControlValueAccessor features
  writeValue(obj: any): void {
    if (!obj) return;

    this.externalValue = obj
    if (this.externalValue.indexOf(commentForMonaco) >= 0) {
      this.monacoEditorActive = true;
      this.internalValue = this.externalValue.replace(commentForMonaco, '');
      setTimeout(() => { this.editorType = 'Monaco' })
    } else {
      this.monacoEditorActive = false;
      this.internalValue = this.externalValue.replace(commentForTinyMCE, '');
      setTimeout(() => { this.editorType = 'TinyMCE' })
    }

  }

  registerOnChange(fn: any): void {
    // this.onChange = fn;
    this.onChange = (value: any) => {
      const prefix = (this.editorType === 'Monaco') ? commentForMonaco : commentForTinyMCE;
      this.internalValue = value
      this.externalValue = prefix + value;
      fn(this.externalValue)
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.mceEditor.setDisabledState(isDisabled);
    this.monacoEditor.setDisabledState(isDisabled);
  }




}
